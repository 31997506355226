import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { toast } from "react-toastify";
import {
  createGiftCardRequest,
  updateGiftCardRequest,
} from "services/modules/giftCards";
import setAuthToken from "services/modules/utils/handel_api";
import { deleteProductRequest } from "services/modules/products";
import { Stack, Typography } from "@mui/material";
import CustomersTemplate from "templates/users/customers";
import {
  getCustomerDetailsRequest,
  getCustomersRequest,
  updateCustomerRequest,
} from "services/modules/users/customers";
import SuspendModel from "components/Molecules/users/model/suspendModel";
import ActiveModel from "components/Molecules/users/model/activeModel";
import DeleteModel from "components/Molecules/users/model/deleteModel";

function CustomersPage() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const username = searchParams.get("username");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.customers.customers);
  const count = useSelector((state) => state.customers.count);
  const details = useSelector((state) => state.customers.customer);
  const followings = useSelector((state) => state.customers.followings);
  const followers = useSelector((state) => state.customers.followers);
  const load = useSelector((state) => state.customers.load);
  const currentUser = useSelector(
    (state) => state.authenticate.currentUserProfile
  );
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.customers
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  // const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openActionsModal, setOpenActionsModal] = useState({
    state: false,
    action: false,
    customerId: 0,
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    const requestData = {
      id: id,
    };
    dispatch(getCustomerDetailsRequest({ ...requestData }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getCustomersRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    const isActive = data?.status == "active";
    return {
      ...data,
      is_verified: data?.is_verified ? "yes" : "no",
      profile_status: (
        <Stack
          sx={{
            p: 0.8,
            borderRadius: "4px",
            backgroundColor: isActive ? "#E1F5E8" : "#FAE3E3",
            width: "fit-content",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{
              color: isActive ? "#29B95F" : "#D20000",
            }}
          >
            {data?.status}
          </Typography>
        </Stack>
      ),
      phone_number: `+${data?.country_code}${data?.phone_number}`,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/customers",
      title: "Users/Customers",
    },
    type && {
      active: username ? true : false,
      path: `?type=details&id=${id}`,
      title: type === "details" && "Customers details",
    },
    username && {
      active: false,
      title: username && username,
    },
  ];

  const handelSubmit = () => {};

  const filterList = [
    {
      type: "search",
      placeholder: "Search by name",
      onChange: (value) =>
        setFilterObj((s) => {
          return { ...s, keyword: value?.trim(), page_number: 1 };
        }),
      setFilterObj,
    },
    {
      type: "filter",
      placeholder: "Profile Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, status: value?.label?.toLowerCase(), page_number: 1 };
        });
      },
      options: [
        { id: "active", name: "Active" },
        { id: "suspended", name: "Suspended" },
        { id: "deleted", name: "Deleted" },
      ],
    },
    {
      type: "filter",
      placeholder: "Verification",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, is_verified: value?.value, page_number: 1 };
        });
      },
      options: [
        { id: true, name: "Verified" },
        { id: false, name: "Not Verified" },
      ],
    },
  ];

  const handleDelete = () => {};

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    const profileStatus = data?.status;
    return [
      {
        condition: () => {
          return !id;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return (
            profileStatus == "active" &&
            (isSuperAdmin || permissions?.can_update)
          );
        },
        click: () => {
          // navigate(`?type=form&id=${data?.id}`);
          setOpenActionsModal({
            state: true,
            customerId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "suspended",
          });
        },
        text: "Suspend",
      },
      {
        condition: () => {
          return (
            profileStatus == "suspended" &&
            (isSuperAdmin || permissions?.can_update)
          );
        },
        click: () => {
          // navigate(`?type=form&id=${data?.id}`);
          setOpenActionsModal({
            state: true,
            customerId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "active",
          });
        },
        text: "Active",
      },
      {
        condition: () => {
          return (
            data?.status != "deleted" &&
            (isSuperAdmin || permissions?.can_delete)
          );
        },
        click: () => {
          setOpenActionsModal({
            state: true,
            customerId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "deleted",
          });
        },
        text: "Delete",
      },
    ];
  };

  //listing all tags
  useEffect(() => {
    if (filterObj && type != "details") getList(filterObj);
  }, [filterObj, type]);

  //details of a single tag
  useEffect(() => {
    if (id && type == "details") getDetails();
  }, [id, type]);

  const handleUpdateProfileState = () => {
    const action = () => {
      toast.success(
        `Account has been ${openActionsModal?.action} successfully`
      );
    };
    const requestData = {
      body: {
        profile_status: openActionsModal?.action,
      },
      id: openActionsModal?.customerId,
      action,
    };
    dispatch(updateCustomerRequest({ ...requestData }));
  };
  return (
    <>
      <Loader
        open={
          (load?.loading && load?.type == "list") || load?.type == "details"
        }
      />
      <CustomersTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        handleDeleteRequest={handleDelete}
        followers={followers}
        followings={followings}
      />
      {openActionsModal?.state && openActionsModal?.action == "active" && (
        <ActiveModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={handleUpdateProfileState}
          userType={"customer"}
        />
      )}
      {openActionsModal?.state && openActionsModal?.action == "suspended" && (
        <SuspendModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={handleUpdateProfileState}
          userType={"customer"}
        />
      )}
      {openActionsModal?.state && openActionsModal?.action == "deleted" && (
        <DeleteModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={handleUpdateProfileState}
          userType={"customer"}
        />
      )}
    </>
  );
}

export default CustomersPage;
