import { Box, Stack } from "@mui/material";
import React from "react";
import CInput from "./components/CInput";
import CPasswordInput from "../main/components/CTextArea";
import CTextArea from "../main/components/CTextArea";
import SelectOptions from "./components/CSelect";
import CCheckbox from "./components/CCheckbox";
import CUpload from "./components/CUpload";
import FormHead from "./FormHead/FormHead";
import { EditorInput } from "./components/CEditableInput";
import CRadioBox from "./components/CRadioBox";
import CColorInput from "./components/CColorInput";
import CMultiUpload from "./components/CMultiupload";
import CMultiSelect from "./components/CMultiSelect";
import BasicDatePicker from "components/Atoms/Input/DatePicker/DatePicker";
import CPhoneInput from "./components/CPhoneInput";
import SearchableSelect from "components/Atoms/Input/Select/SearchableSelect";

const FormIndex = ({ formStructures, formik, children, className }) => {
  console.log(formik?.values, "structure");

  const renderInputs = (data, index) => {
    const generalProps = {
      ...data,
      formik: formik,
      colSpan: data?.colSpan,
      index: index,
    };
    if (data.kind === "input") return <CInput {...generalProps} />;
    if (data.kind === "passwordInput") return <CPasswordInput {...generalProps} />;
    if (data.kind === "textArea") return <CTextArea {...generalProps} />;
    if (data.kind === "select")
      return (
        <SelectOptions {...generalProps} changeAction={(value) => formik.setValues({ ...formik?.values, [data?.name]: value })} />
      );
    // if (data.kind === "select")
    //   return (
    //     <SearchableSelect
    //       {...generalProps}
    //       onChange={(value) => {
    //         console.log(value);
    //         formik.setValues({ ...formik?.values, [data?.name]: value?.value })
    //       }
    //       }
    //     />
    //   );
    if (data.kind === "date")
      return (
        <BasicDatePicker
          {...generalProps}
          sx={{
            width: "100%",
            bgcolor: "#FBFBFB",
            "&.MuiOutlinedInput-notchedOutline": {
              borderColor: "red",
            },
          }}
          onChange={(value) => formik.setValues({ ...formik?.values, [data?.name]: value })}
        />
      );
    if (data.kind === "multiSelect")
      return (
        <CMultiSelect {...generalProps} changeAction={(value) => formik.setValues({ ...formik?.values, [data?.name]: value })} />
      );
    if (data.kind === "checkbox")
      return (
        <CCheckbox {...generalProps} changeAction={(value) => formik.setValues({ ...formik?.values, [data?.name]: value })} />
      );
    if (data.kind === "phone")
      return (
        <CPhoneInput
          {...generalProps}
          changeAction={(value, country, e, formattedValue) => {
            formik.setValues({
              ...formik?.values,
              phone_number: value,
              country_code: `+${country?.dialCode}`,
            });
          }}
        />
      );
    if (data.kind === "upload")
      return <CUpload {...generalProps} changeAction={(value) => formik.setValues({ ...formik?.values, [data?.name]: value })} />;
    if (data.kind === "multiUpload")
      return (
        <CMultiUpload {...generalProps} changeAction={(value) => formik.setValues({ ...formik?.values, [data?.name]: value })} />
      );
    if (data.kind === "editorInput")
      return (
        <EditorInput {...generalProps} changeAction={(value) => formik.setValues({ ...formik?.values, [data?.name]: value })} />
      );
    if (data.kind === "radioBox")
      return (
        <CRadioBox {...generalProps} changeAction={(value) => formik.setValues({ ...formik?.values, [data?.name]: +value })} />
      );
    if (data.kind === "colorInut")
      return (
        <CColorInput {...generalProps} changeAction={(value) => formik.setValues({ ...formik?.values, [data?.name]: value })} />
      );
  };
  return (
    <form onSubmit={formik?.handleSubmit}>
      <Stack gap={2} width="100%" flexWrap="wrap" flexDirection="row">
        {formStructures?.map((data, index) => {
          return (
            <Box key={index} className={className} width={data?.width || "100%"} bgcolor="white" p={3}>
              <FormHead text={data?.head} />
              <Stack gap={3} width="100%" flexWrap="wrap" flexDirection="row">
                {data?.list?.map((val, index) => {
                  if (val)
                    return (
                      <Box
                        width={val.width || "100%"}
                        key={index}
                        sx={{
                          flexDirection: "column",
                          display: "flex",
                        }}
                      >
                        {renderInputs(val, index)}
                      </Box>
                    );
                })}
              </Stack>
            </Box>
          );
        })}
      </Stack>
      {children}
    </form>
  );
};

export default FormIndex;
