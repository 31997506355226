import Types from "./Types";
export const AuthSuccess = (payload) => ({
  type: Types.AUTH_SUCCESS,
  payload,
});
export const AuthLoading = (payload) => ({
  type: Types.AUTH_LOADING,
  payload,
});

export const GetUserProfileSuccess = (payload) => ({
  type: Types.GET_USER_PROFILE_SUCCESS,
  payload,
});

export const logoutSuccess = (payload) => ({
  type: Types.LOGOUT_SUCCESS,
});
