import { Button, Rating, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import CActions from "components/Molecules/Crud/components/actions";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCitiesRequest } from "services/modules/cities";
import {
  createVariantRequest,
  deleteProductVariantRequest,
  getProductsRequest,
  getProductVariantsRequest,
  getVariantDetailsRequest,
  updateProductRequest,
  updateVariantRequest,
} from "services/modules/products";
import ProductVariantTemplate from "templates/products/productVariant";
import VendorProductsTemplate from "../../templates/vendor/vendorProducts";
import OrderStatus from "../../components/Molecules/Products/orderStatus";
import ApprovalStatus from "../../components/Molecules/Products/approvalStatus";
import Loader from "../../components/Atoms/SharedComponents/Loader/Loader";

export default function VendorProductsPage({ isHeaderChildren = true, productId, optionTypes, refOpenVriantForm }) {
  const dispatch = useDispatch();
  const { orange } = useThemePalette();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id") ?? productId;
  const list = useSelector((state) => state.products.products);
  console.log(list);
  const details = useSelector((state) => state.products.product);
  const count = useSelector((state) => state.products.count);
  const load = useSelector((state) => state.products.load);
  const fulfillmentCenters = useSelector((state) => state.cities.cities).filter((city) => city?.is_fulfillment_center);
  const [locations, setLocations] = useState([]);
  const [locationError, setLocationError] = useState("");
  const navigate = useNavigate();

  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tableData, setTableData] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const variantId = selectedRow?.rowData?.id;

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);

  const [filterObj, setFilterObj] = useState({
    page_size: 10,
    page_number: 1,
    keyword: "",
    sort_by: "id",
    store_id: id,
  });

  const handleProductApprove = (id) => {
    const requestData = {
      body: {
        status: "available",
      },
      id,
    };
    dispatch(updateProductRequest(requestData));
  };
  const handleProductReject = (id) => {
    const requestData = {
      body: {
        status: "rejected",
      },
      id,
    };
    dispatch(updateProductRequest(requestData));
  };

  console.log(productId);
  const columns = [
    { accessor: "id", Header: "#" },
    {
      accessor: "localized_data.en.name",
      Header: "Name(EN)",
      sort: true,
    },
    {
      accessor: "localized_data.ar.name",
      Header: "Name(AR)",
      sort: true,
    },
    { accessor: "occasion", Header: "Occasion", sort: true },
    { accessor: "price", Header: "Price", sort: true },
    {
      accessor: "price_after_discount",
      Header: "Price after discount",
      sort: true,
    },
    { accessor: "total_on_hand", Header: "Quantity", sort: true },
    {
      accessor: "rate",
      Header: "Avg rate/Reviews count",
      sort: true,
    },
    { accessor: "status", Header: "status" },
    { accessor: "approval_status", Header: "Approval Status" },
    { accessor: "Actions", Header: "Actions" },
  ];
  const actionsList = ({ index, data }) => {
    const status = data?.status;

    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`/products/?type=details&id=${selectedRow?.rowData?.id}`);
        },
        text: "View Details",
      },
      {
        condition: () => {
          return status == "available";
        },
        click: () => {
          navigate(`/products/?type=details&id=${data?.id}&tab=1`);
        },
        text: "View Variants",
      },
      {
        condition: () => {
          return status == "unverified" || status == "rejected";
        },
        click: () => {
          handleProductApprove(data?.id);
        },
        text: "Approve",
      },
      {
        condition: () => {
          return status == "unverified" || status == "available";
        },
        click: () => {
          handleProductReject(data?.id);
        },
        text: "Reject",
      },
      {
        condition: () => {
          return status == "available";
        },
        click: () => {
          navigate(`/products/${data?.id}?type=edit`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return status == "available" || status == "rejected";
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  const optionTypesFilter = () => {
    if (!optionTypes) return [];
    const filterData = optionTypes?.map((option) => {
      const filterName = option?.name;
      return {
        type: "filter",
        placeholder: filterName,
        name: filterName,
        id: option?.id,
        onChange: (value) => {
          setFilterObj((s) => {
            return { ...s, [filterName]: value?.value };
          });
        },
        options: option?.option_values,
      };
    });
    return filterData;
  };

  const filterList = [
    {
      type: "filter",
      placeholder: "Occasion",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, city_id: value?.value, page_number: 1 };
        });
      },
      options: [...fulfillmentCenters],
    },
    {
      type: "filter",
      placeholder: "Approval Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, city_id: value?.value, page_number: 1 };
        });
      },
      options: [...fulfillmentCenters],
    },
    {
      type: "filter",
      placeholder: "Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, city_id: value?.value, page_number: 1 };
        });
      },
      options: [...fulfillmentCenters],
    },
  ].concat(...optionTypesFilter());

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };
  console.log(selectedRow);

  const handleDelete = () => {
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        // getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        // getList(filterObj);
      }
      toast.success("Variant deleted successfully");
    };
    const requestData = {
      id: selectedRow?.rowData?.id,
      action, //dispatched on success
    };
    dispatch(deleteProductVariantRequest(requestData));
    setOpenDeleteModal(false);
  };

  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Actions = (id, elmIndex, data) => {
    return (
      <CActions
        id={id}
        index={elmIndex}
        data={data}
        handleClick={handleClick}
        handleClose={handleClose}
        anchorRef={anchorRef}
        open={open}
        anchorEl={anchorEl}
        actionsList={actionsList}
      />
    );
  };

  const mappedData = list?.map((data, index) => {
    const quantity = data?.city_variant?.reduce((acc, current) => acc + current.count_on_hand, 0);
    return {
      ...data,
      rate: (
        <Stack direction="row" alignItems="center">
          <Rating name="read-only" value={data?.avg_rate} readOnly precision={0.5} />
          <Typography>{data?.avg_rate}</Typography>
          <Typography>({data?.reviews_count})</Typography>
        </Stack>
      ),
      occasion: <Stack width="200px">{data?.occasion_types}</Stack>,
      status: <OrderStatus data={data} />,
      approval_status: <ApprovalStatus data={data} />,
      quantity: <Typography>{quantity}</Typography>,
      price: (
        <Typography>
          {data?.price} {data?.currency?.name}
        </Typography>
      ),
      price_after_discount: (
        <Typography>
          {data?.price_after_discount} {data?.currency?.name}
        </Typography>
      ),
      location: (
        <Stack direction="row">
          {data?.city_variant?.map((city, i) => (
            <Typography key={i}>
              {city?.city?.name}
              {i !== data?.city_variant?.length - 1 && ", "}
            </Typography>
          ))}
        </Stack>
      ),
      Actions: Actions(data?.id, index, data),
    };
  });

  const handleOpenVariantForm = () => {
    setSelectedRow({});
    setIsOpenForm(true);
  };

  const headerChildren = (
    <Stack sx={{ position: "absolute", mr: 5 }}>
      <Button
        onClick={handleOpenVariantForm}
        ref={refOpenVriantForm}
        sx={{ color: orange, fontWeight: "500", fontSize: "20px", px: 0 }}
      ></Button>
    </Stack>
  );

  const isValidLocation = () => {
    for (let i = 0; i <= locations.length - 1; i++) {
      if (locations[i].city == "") {
        setLocationError("Please choose a location");
        return false;
      } else if (locations[i].quantity == "") {
        setLocationError("Please enter quantity");
        return false;
      }
    }
    setLocationError("");
    return true;
  };

  const handleSubmit = (data, formik) => {
    const isValid = isValidLocation();
    if (!isValid) return;

    const optTypes = optionTypes.map((option) => option.name);
    const option_value_ids = optTypes.map((type) => data[type]);

    const requestData = {
      body: {
        variant: {
          ...data,
          product_id: id,
          option_value_ids,
          // city_variant: locations,
          city_variant: [
            {
              count_on_hand: 250,
              city: {
                id: 1,
              },
            },
          ],
        },
      },
    };
    console.log(requestData);
    if (variantId) {
      dispatch(updateVariantRequest({ ...requestData, id: variantId }));
    } else {
      dispatch(createVariantRequest(requestData));
    }
  };

  const handleVariantFormClose = () => {
    // setLocations([{ city: "", quantity: 0 }]);
    setLocationError("");
    setIsOpenForm(false);
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: filterObj,
    };
    dispatch(getProductsRequest(requestData));
  };

  const getDetails = async () => {
    dispatch(getVariantDetailsRequest({ id: variantId }));
  };

  useEffect(() => {
    const requestData = {
      params: {
        page_size: 300, //to get all data
        page_number: 1,
        keyword: "",
        is_fulfillment_center: true,
        sort_by: "id",
      },
    };
    //get fulfillment centers
    dispatch(getCitiesRequest(requestData));
  }, []);

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  useEffect(() => {
    rowData({ ...tableData });
  }, [tableData]);

  return (
    <>
      <Loader open={load?.loading} />
      <VendorProductsTemplate
        isOpenForm={isOpenForm}
        isOpenDetails={isOpenDetails}
        setIsOpenDetails={setIsOpenDetails}
        variantId={variantId}
        fulfillmentCenters={fulfillmentCenters}
        details={details}
        setOpenDeleteModal={setOpenDeleteModal}
        openDeleteModal={openDeleteModal}
        handleDelete={handleDelete}
        handleSubmit={handleSubmit}
        locations={locations}
        setLocations={setLocations}
        tableData={tableData}
        list={mappedData}
        columns={columns}
        setTableData={setTableData}
        setFilterObj={setFilterObj}
        filterList={filterList}
        headerChildren={headerChildren}
        actionsList={actionsList}
        rowData={rowData}
        handlePageChange={handlePageChange}
        page={page}
        count={count}
        optionTypesFilter={optionTypesFilter()}
        handleVariantFormClose={handleVariantFormClose}
        locationError={locationError}
      />
    </>
  );
}
