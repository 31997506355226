import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import React from "react";
import CFormErrors from "./CFormErrors";
import { icons } from "assets/AssetHelper";

import { useThemePalette } from "common/hooks/theme_palette";
import { useEffect } from "react";
import { useState } from "react";

const CCheckbox = ({ label, name, formik, changeAction }) => {
  const { orange } = useThemePalette();
  const errorsMsg = formik.errors[name];
  const value = formik.values[name];
  const [key, setKey] = useState(0);
  const customUncheckedIcon = (
    <span
      style={{
        borderRadius: "4px",
        backgroundColor: "#F4F4F4",
        width: "32px",
        height: "32px",
      }}
    />
  );

  useEffect(() => {
    setKey((k) => key + 1);
  }, [value]);

  const customCheckedIcon = (
    <Stack
      justifyContent="center"
      alignItems="center"
      style={{
        borderRadius: "4px",
        backgroundColor: "red",
        width: "32px",
        height: "32px",
      }}
    >
      <Box component="img" src={icons?.checkMark} />
    </Stack>
  );
  return (
    <Box mb="5" sx={{ mt: "auto" }}>
      <FormControlLabel
        control={
          <Checkbox
            lineHeight="1.25"
            alignItems="flex-start"
            fontWeight="normal"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 38, color: orange } }}
            name={name}
            value={value}
            checked={value || ""}
            onBlur={formik.handleBlur}
            onChange={(e) => changeAction(e?.target?.checked)}
            icon={customUncheckedIcon} // Custom icon for unchecked state
            checkedIcon={customCheckedIcon}
          />
        }
        label={label}
        sx={{
          ".MuiFormControlLabel-label": {
            fontSize: "20px",
          },
        }}
      />
      <CFormErrors errorsMsg={errorsMsg} />
    </Box>
  );
};

export default CCheckbox;
