import { Grid, Stack, Typography } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import SideNavbar from "./SideNavbar";
import Header from "./Header";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import setAuthToken from "services/modules/utils/handel_api";
import { getUserProfileRequest } from "services/modules/authenticate";

const Layout = ({ notificationUnseenCount }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { currentUserProfile } = useSelector((state) => state?.authenticate);

  const action = (data) => {
    currentUserProfile && localStorage.setItem("user_id", data?.data?.user?.id);
  };

  const token = localStorage.getItem("token");

  useEffect(() => {
    setAuthToken(token);
    const requestData = {
      action,
    };
    token && dispatch(getUserProfileRequest({ ...requestData }));
  }, [token]);
  const num = [1, 2, 3, 4, 5, 6];

  return (
    <Grid
      container
      sx={{
        direction: "row",
        justifyContent: "space-between",
        display: "flex",
      }}
    >
      <Grid item display={{ xs: "none", lg: "grid" }} lg={2.7} xl={2}>
        <SideNavbar />
      </Grid>
      <Grid
        item
        xs={12}
        lg={9.3}
        xl={10}
        sx={{
          backgroundColor: "#F5F5F5",
          overflowY: "scroll",
          height: "100vh",
        }}
      >
        <Header
          notificationUnseenCount={notificationUnseenCount}
          currentUserProfile={currentUserProfile}
        />
        <Stack p={pathname != "/messenger" && 3}>
          {pathname != "/messenger" && <Breadcrumb />}
          <Outlet />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Layout;
