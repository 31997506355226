import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


import {getCitiesRequest} from "../../services/modules/cities";
import Locations from "../../components/Atoms/SharedComponents/Vendor/Locations/Locations";
import {getTagsRequest} from "../../services/modules/lookups/tags";
import Status from "../../components/Atoms/SharedComponents/Vendor/Status/status";
import VendorRegistrationTemplate from "../../templates/vendor/vendorRegistration/vendorRegistration";
import {useFormik} from "formik";

function VendorRegistration() {
    const dispatch=useDispatch()
    useEffect(()=>{
        const requestData = {
            params: {
                page_size: 10,
                page_number: 1,
                keyword: "",
                sort_by: "id",
            },
        }   ;
        dispatch(getCitiesRequest(requestData))
        dispatch(getTagsRequest(requestData))
    },[])

    return (
        <>
            <VendorRegistrationTemplate/>
        </>
    );
}

export default VendorRegistration;
