import {
    Divider,
    FormControlLabel,
    Stack,
    Typography,
    Checkbox, Box, Button,
} from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import React, {useEffect, useRef, useState} from "react";
import {icons} from "../../../../assets/AssetHelper";
import {addVendorRequest} from "../../../../services/modules/vendors";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import {initialValues, structure, validationSchema} from "../../../../templates/vendor/vendorRegistration/structure";
import FormIndex from "../../../Atoms/SharedComponents/Forms/main";
import Locations from "../../../Atoms/SharedComponents/Vendor/Locations/Locations";
export default function RegistrationForm({details,id}) {
    const { load } = useSelector((state) => state?.authenticate);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { orange,oliveGreen,red } = useThemePalette();
    const tags=useSelector(state=>state.tags.tags)
    const refsubmit = useRef();
    console.log(tags)
    const formik = useFormik({
        initialValues: !id ? {} : initialValues(details,tags),
        validationSchema,
        onSubmit: async (values) => {
            handleSubmit(values, formik);
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const handleClose = () => {
        formik.resetForm();
    };
    console.log(formik?.values)
    const cities=useSelector(state=>state.cities.cities)

    const handleSubmit=(values, formik)=>{
        const action = () => {

            formik?.resetForm();
            navigate('/')
            toast.success(`We successfully received your application We will return back to you as soon as possible`);
        };
        const tags=formik?.values?.tags.map(tag=>tag.value)
        const countryCode = values?.country_code.startsWith('+') ? values?.country_code.slice(1) : values?.country_code;
        const phoneNumberWithoutCountryCode = values?.phone_number.slice(countryCode.length);

        const requestData={
            body:{
                store:{
                    ...values,
                    tag_ids:tags,
                    avatar:values?.image,
                    logo:values?.image,
                    phone_number:phoneNumberWithoutCountryCode,
                },
                "device": {
                    "device_type": "android",
                    "fcm_token": "fcm_token",                    }
            },action
        }
        console.log(requestData)
        dispatch(addVendorRequest(requestData))
        console.log(values)
    }

    return (
        <Stack backgroundColor={'#F5F5F5'}  onSubmit={formik.handleSubmit}         component="form" >
            <Stack backgroundColor={'#F5F5F5'} height={'100%'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
            <Loader open={load?.loading} />
            <Stack  width={'80%'} height={'70%'}  mt={8} mb={4} backgroundColor={'white'}>
            <Box

                p={3}
            >
            <Stack  sx={{ gap: 4 }}>
                <img src={icons.logoIcon} alt={'logo'} style={{width:'135px', height:'75px'}}/>
                <Stack gap={2}>
                <Typography fontFamily="jost" fontSize="38px" fontWeight='500'>
                    Welcome to our platform!
                </Typography>
                <Typography fontSize="24px" fontWeight="400">
                    Please fill out your information
                </Typography>
                </Stack>
                <FormIndex formStructures={structure(tags)} formik={formik} />

                <Stack >
                    <Locations data={cities} registrationFormik={formik}  ></Locations>
                </Stack>
            </Stack>
            </Box>

            </Stack>

            </Stack>
            <Stack flexDirection={'row'}  position={'relative'} ml={90} mb={5}  gap={3}  >
                <Button
                    variant={'outlined'}
                    sx={{borderColor:red,color:red,'&:hover':{borderColor:red,color:red}}}
                    onClick={()=>navigate('/')} >
                    Cancel
                </Button>
                <Button
                    variant={'contained'}
                    sx={{background:oliveGreen,'&:hover':{background:oliveGreen}}}
                     type={'submit'}
                        >Register</Button>
            </Stack>

        </Stack>
    );
}
