import React from "react";
import ConfirmationModal from "../Models/ConfirmationModal";
import { CircularProgress } from "@mui/material";

export default function UnAssignDriver({
  load,
  openUnAssignModal,
  setOpenUnAssignModal,
  handleUnAssignDriver,
  data,
  routeType,
}) {
  return (
    <>
      <ConfirmationModal
        open={openUnAssignModal}
        setOpen={setOpenUnAssignModal}
        handleConfirmation={(_) => {
          handleUnAssignDriver({
            driverId: data?.driver?.id,
            routeType: routeType,
          });
        }}
        text={[
          "Unassign Driver",
          "Are you sure you want to unassign this driver?",
        ]}
        textStyle={{ color: "#000", fontSize: "32px" }}
        confirmBtnTitle={
            load?.loading && load?.type == "unassignDriver" ? (
          <CircularProgress
            sx={{
              color: "white",
              width: "33px !important",
              height: "33px !important",
            }}
          />
            )
             : (
              "Unassign"
            )
        }
        btnStyle={{
          backgroundColor: "#000",
          "&:hover": {
            backgroundColor: "#000",
          },
          color: "white",
        }}
      />
    </>
  );
}
