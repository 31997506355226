import React, { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DetailsUse from "../../Atoms/SharedComponents/CRUD/details-use";
import TableUse from "../../Atoms/SharedComponents/CRUD/table-use";
import FormUse from "../../Atoms/SharedComponents/CRUD/form-use";
import { useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import CActions from "./components/actions";
import DeleteModal from "../Models/DeleteModal";
import ConfirmationModal from "../Models/ConfirmationModal";

const CCrud = ({ table, form, DetailsComponent, modalComponents, detailsTemplateData }) => {
  const {
    type,
    list,
    emptyListMsg,
    searchAttribute,
    details,
    actionsList,
    columns,
    isChecked,
    isSearch,
    isFilter,
    className,
    createBtnTitle,
    createBtnFun,
    CreateBtnIcon,
    createBtnClass,
    listTitle,
    setFilterObj,
    total_count,
    filterList,
    paginationPage,
    searchInputPlaceholder,
    handlePageChange,
    rowData,
    openDeleteModal,
    setOpenDeleteModal,
    handleDeleteRequest,
    headerChildren,
    isDetails,
    openConfirmationModal,
    setOpenConfirmationModal,
    handleConfirmation,
    confirmationText,
    confirmBtnTitle,
    color,
    btnColor,
  } = table;

  const formik = useFormik({
    initialValues: form?.initialValues,
    validationSchema: form?.validationSchema,
    onSubmit: async (values) => {
      form?.onSubmit(values, formik);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (Object.keys(formik?.errors).length !== 0 && formik?.isSubmitting) {
      const msg = Object.keys(formik?.errors).map(
        (err) => `${err == "en" ? "English name" : err.split("_").join(" ")}  is required`
      );
      toast.error(msg?.join(", "));
    }
  }, [formik?.errors, formik?.isSubmitting]);

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const display = searchParams.get("type");
  const id = searchParams.get("id");
  const [tableData, setTableData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    display != "form" && formik?.resetForm();
  }, [display]);

  const Actions = (id, elmIndex, data) => {
    return (
      <CActions
        id={id}
        index={elmIndex}
        data={data}
        form={form}
        handleClick={handleClick}
        handleClose={handleClose}
        anchorRef={anchorRef}
        open={open}
        anchorEl={anchorEl}
        actionsList={actionsList}
        type={type}
      />
    );
  };
  const mappedData = list?.map((data, index) => {
    return { ...data, Actions: Actions(data?.id, index, data) };
  });

  useEffect(() => {
    rowData({ ...tableData, formikData: formik });
  }, [tableData, formik?.values, formik?.errors]);

  const handleModelClose = () => {
    formik.resetForm();
    navigate(window.location.pathname);
    //updating the pagination page,
    //otherwise it will fetch data for page 1, when another page is selected at the footer
    handlePageChange && handlePageChange(1);
  };
  return (
    <>
      {!display && (
        <TableUse
          className={className}
          headerChildren={headerChildren}
          list={mappedData}
          emptyListMsg={emptyListMsg}
          searchAttribute={searchAttribute}
          columnsData={columns}
          isChecked={isChecked}
          setTableData={setTableData}
          listTitle={listTitle}
          isSearch={isSearch}
          isFilter={isFilter}
          setFilterObj={setFilterObj}
          filterList={filterList}
          createBtnTitle={createBtnTitle}
          createBtnFun={createBtnFun}
          CreateBtnIcon={CreateBtnIcon}
          createBtnClass={createBtnClass}
          total_count={total_count}
          paginationPage={paginationPage}
          searchInputPlaceholder={searchInputPlaceholder}
          handlePageChange={handlePageChange}
          isDetails={isDetails}
        />
      )}

      {display == "form" && (
        <FormUse
          handleLanguage={form?.handleLanguage}
          formStructure={form?.structure}
          createBtnFun={() => {
            formik?.resetForm();
          }}
          className={form?.className?.form}
          formHead={form?.formHead}
          formik={formik}
          form={form}
          handleModelClose={handleModelClose}
          breadcrumbs={form?.breadcrumbs}
        >
          {form?.formChildren}
        </FormUse>
      )}

      {(display == "details" || display === "vendorProducts") &&
        (DetailsComponent ? DetailsComponent : <DetailsUse setOpenDeleteModal={setOpenDeleteModal} data={detailsTemplateData} />)}
      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          handelDeleteRequest={() => {
            handleDeleteRequest(tableData?.rowData?.id ? tableData?.rowData?.id : details?.id);
          }}
          handleCancelDelete={() => setOpenDeleteModal(false)}
          customClass="organizations-delete-modal"
          text={[
            // `Delete ${tableData?.rowData?.name}`,
            // `Are you sure that you want to delete ${tableData?.rowData?.name} ?`,
            `Delete ${tableData?.rowData?.name}`,
            `Are you sure that you want to delete ${tableData?.rowData?.name ? tableData?.rowData?.name : details?.name} ?`,
          ]}
        />
      )}

      {modalComponents &&
        modalComponents?.map((modal) => {
          return <>{modal()}</>;
        })}
    </>
  );
};
export default CCrud;
