import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { detailsData } from "./details";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const ProductOptionValuesTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  wrapsOptionsListArray,
  selectedRow,
  can_create,
  optionTypesOptions,
  filterProductsOptionTypes,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [supportedLang, setSupportedLang] = useState("en");
  const [optionType, setOptionType] = useState("en");
  const optionsTypes = useSelector(
    (state) => state.productsOptionTypes.productsOptionTypes
  );
  useEffect(() => {
    const filteredData = filterProductsOptionTypes?.find(
      (ele) => ele?.id == selectedRow?.formikData?.values?.option_type_id
    );
    setOptionType(filteredData?.name);
  }, [selectedRow?.formikData?.values?.option_type_id]);
  return (
    <>
      <Box>
        <Stack
          direction="row"
          alignSelf="start"
          justifyContent="start"
          gap={2}
          width="100%"
          mb={1}
        >
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "Products Option Values",
            data: {
              list: detailsData({ data: details }),
            },
          }}
          table={{
            list: data, // data
            listTitle: "Products Option Values", //title
            createBtnTitle: can_create && "Add Option Value", // action
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              {
                accessor: "option_type.name",
                Header: "Option Type",
                sort: true,
                sortKey: "type",
              },
              {
                accessor: "localized_data.en.name",
                Header: "Option Value(EN)",
                sort: true,
              },
              {
                accessor: "localized_data.ar.name",
                Header: "Option Value(AR)",
                sort: true,
              },

              { accessor: "presentation", Header: "Presentation", sort: true },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: false,
            searchInputPlaceholder: "Search shape..",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            // lw mafesh id add mafesh values -------- lw fe id yb2a edit values
            initialValues: !id ? {} : initialValues(details, supportedLang),
            structure: () =>
              structure(optionsTypes, optionType, optionTypesOptions, details),
            validationSchema: validationSchema(optionType),
            deleteFun: deleteFun,
            title: id ? "Edit Option Value" : "Add Option Value",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default ProductOptionValuesTemplate;
