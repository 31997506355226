import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";

import { toast } from "react-toastify";
import { getScrollCitiesRequest } from "services/modules/cities/cities";
import setAuthToken from "services/modules/utils/handel_api";
import ModelBox from "../../components/Atoms/Model/Model";
import Locations from "../../components/Atoms/SharedComponents/Vendor/Locations/Locations";
import Status from "../../components/Atoms/SharedComponents/Vendor/Status/status";
import ForgetPasswordForm from "../../components/Organisms/login/forms/forgetPasswordForm";
import ResetPasswordForm from "../../components/Organisms/login/forms/resetPassword/resetPasswordForm";
import VerifyOTPForm from "../../components/Organisms/login/forms/verifyOtp/verifyOTPForm";
import { getScrollTagsRequest } from "../../services/modules/lookups/tags";
import {
  GetVendorsRequest,
  addVendorRequest,
  getVendorDetailsRequest,
  updateVendorRequest,
} from "../../services/modules/vendors";
import VendorTemplate from "../../templates/vendor/vendorListing";
import utils from "./utils";

function Vendor() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.vendors.vendors);
  const count = useSelector((state) => state.vendors.count);
  const details = useSelector((state) => state.vendors.vendor);
  const load = useSelector((state) => state.vendors.load);

  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const permissions = useSelector((state) => state.authenticate.currentUserProfile.permissions?.stores);
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [resetPasswordStep, setResetPasswordStep] = useState({
    action: false,
    step: null,
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditRejectModal, setOpenEditRejectModal] = useState({
    action: false,
    reason: "",
    type: "",
  });
  const [approval, setApproval] = useState();

  const [selectedRow, setSelectedRow] = useState({});
  const [rejectionReason, setRejectionReason] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [status, setStatus] = useState({ display: "", api: "" });
  const [page, setPage] = useState(1);
  const handleResetPassword = () => {
    setResetPasswordStep({ action: true, step: 1 });
  };
  const [filterObj, setFilterObj] = useState(null);
  const cities = useSelector((state) => state.cities.cities);
  const tags = useSelector((state) => state.tags.tags);
  const filterCitites = useSelector((state) => state.cities.filterCitites);
  const cititesCount = useSelector((state) => state.cities.listCount);
  const cititesLoad = useSelector((state) => state.cities.laod);
  const filterTags = useSelector((state) => state.tags.filterTags);
  const tagsCount = useSelector((state) => state.tags.listCount);
  const [location, setLocation] = useState({
    lat: 24.7136,
    lng: 46.6753,
  });
  const tagsLoad = useSelector((state) => state.tags.load);

  const approvalStatusOptions = [
    { id: "approved", name: "Approved" },
    { id: "pending_approval", name: "Pending" },
    { id: "rejected", name: "Rejected" },
  ];

  const getCurrentLocation = (lat, lng) => {
    setLocation({ lat, lng });
  };

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getVendorDetailsRequest({ id: id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetVendorsRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      // button: <Button variant="outline">test</Button>,
      status: (
        <Status
          key={index}
          data={data}
          status={data?.details?.profile_status}
          setOpenRejection={setOpenEditRejectModal}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
          setApproval={setApproval}
        ></Status>
      ),
      profile_status: (
        <Status
          setOpenRejection={setOpenEditRejectModal}
          key={index}
          data={data}
          status={data?.details?.status}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
          setApproval={setApproval}
        ></Status>
      ),
      city: data?.location?.map((location) => location.google_city?.name).join(", "),
      logo: <img src={data?.logo} alt={data?.name} width="88px" height="88px" style={{ border: "solid #DDDDDD" }} />,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/vendors",
      title: "Vendors",
    },
    type && {
      active: false,
      title: type === "details" ? "Vendor details" : id ? "Edit Vendor" : "Add Vendor",
    },
  ];

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      !id && formik?.resetForm();
      toast.success(`Vendor ${id ? "updated" : "created"}  successfully`);
      navigate("/vendors");
    };
    const tags = formik?.values?.tags.map((tag) => tag.value);
    const excludedValues = ["tags"];
    const countryCode = data?.country_code.startsWith("+") ? data?.country_code.slice(1) : data?.country_code;
    const phoneNumberWithoutCountryCode = data?.phone_number.slice(countryCode.length);

    const filteredValues = Object.keys(formik?.values || {}).reduce((acc, key) => {
      if (!excludedValues.includes(key)) {
        acc[key] = formik?.values[key];
      }
      return acc;
    }, {});

    const requestData = {
      body: {
        store: {
          ...filteredValues,
          logo: formik?.values?.image,
          avatar: formik?.values?.image,
          profile_status: "approved",
          phone_number: phoneNumberWithoutCountryCode,
          tag_ids: formik?.values?.tags.map((tag) => tag.value),
        },
        device: {
          device_type: "android",
          fcm_token: "fcm_token",
        },
      },
      action,
    };
    if (!id) {
      dispatch(addVendorRequest(requestData));
    }
  };
  const getCities = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10, //to get all data
        page_number: 1,
        keyword: "",
        is_fulfillment_center: true,
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollCitiesRequest(requestData));
  };
  const getTags = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10, //to get all data
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollTagsRequest(requestData));
  };

  const { filterList, actionsList } = utils({
    cities,
    approvalStatusOptions,
    setFilterObj,
    tags,
    handleResetPassword,
    setStatus,
    setOpenConfirmationModal,
    setApproval,
    setOpenEditRejectModal,
    setRejectionReason,
    navigate,
    getCities,
    filterCitites,
    cititesCount,
    getTags,
    filterTags,
    tagsCount,
    isSuperAdmin,
    permissions,
  });

  const handleDelete = (id) => {};
  const handleRejectionSubmit = () => {
    console.log(rejectionReason);
    const requestData = {
      body: {
        store: {
          profile_status: "rejected",
          rejection_reason: openEditRejectModal.reason,
        },
      },
      action: () => {
        setOpenEditRejectModal({ action: false, reason: "", type: "" });
        const requestData = {
          params: {
            page_size: 10,
            page_number: 1,
            keyword: "",
            sort_by: "id",
            ...filterObj,
          },
        };
        getList(requestData);
        toast.success("vendor is rejected successfully");
      },
    };
    dispatch(updateVendorRequest({ ...requestData, id: selectedRow?.rowData?.id }));
  };
  const handleStatus = () => {
    const action = () => {
      toast.success(`vendor is ${status.display} successfully`);
      // getList(filterObj);
    };
    const requestData = {
      body: {
        store: {
          ...(status.api === "approved" ? { profile_status: status.api } : { status: status.api }),
        },
      },
      action,
    };
    dispatch(
      updateVendorRequest({
        ...requestData,
        id: id || selectedRow?.rowData?.id,
      })
    );
    setOpenConfirmationModal(false);
  };
  //list of actions to be displayed when clicked in the 3 dots

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const formChildren = () => {
    return <Locations registrationFormik={selectedRow?.formikData} data={details} id={id}></Locations>;
  };
  let options = {
    tags: {
      options: filterTags,
      onOpen: filterTags?.length <= 1 && getTags,
      isLoading: tagsLoad?.loading && tagsLoad?.type == "list-filter-tags",
      getList: getTags,
      count: tagsCount,
    },
  };
  return (
    <>
      <Loader open={(load?.loading && load?.type == "list") || load?.type == "update vendor" || load?.type == "details"} />
      <VendorTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        formChildren={formChildren()}
        openEditReason={openEditRejectModal}
        setEditReason={setOpenEditRejectModal}
        rejectionReason={rejectionReason}
        setRejectionReason={setRejectionReason}
        handleRejectionSubmit={handleRejectionSubmit}
        approval={approval}
        setApproval={setApproval}
        openConfirmation={openConfirmationModal}
        setOpenConfirmation={setOpenConfirmationModal}
        status={status}
        handleProfileStatus={handleStatus}
        approvalStatusOptions={approvalStatusOptions}
        options={options}
      />
      <ModelBox
        openVar={resetPasswordStep.action}
        closeFunc={() =>
          setResetPasswordStep({
            action: false,
            step: resetPasswordStep.step,
          })
        }
        title={"Reset Password"}
        titleStyle={{ fontSize: "28px", fontWeight: 500 }}
      >
        {resetPasswordStep.step === 1 ? (
          <ForgetPasswordForm
            hideIcon={true}
            setResetPasswordStep={setResetPasswordStep}
            customAction={true}
            userType={"store"}
          />
        ) : resetPasswordStep.step === 2 ? (
          <VerifyOTPForm
            hideIcon={true}
            setResetPasswordStep={setResetPasswordStep}
            customAction={true}
            userType={"store"}
          ></VerifyOTPForm>
        ) : (
          <ResetPasswordForm
            hideIcon={true}
            setResetPasswordStep={setResetPasswordStep}
            customAction={true}
            userType={"store"}
          ></ResetPasswordForm>
        )}
      </ModelBox>
    </>
  );
}

export default Vendor;
