import * as Yup from "yup";
import {useSelector} from "react-redux";
import details from "../../orders/details";
export const initialValues = (data,tags) => {
    const tagsArray=data?.tags?.split(', ')

    const selectedTags=tagsArray?.map((name)=>{
        const item=tags.find(tag=>tag.name===name)
        return item&&{value:name,id:item.id}
    })
    console.log(selectedTags)

    return {
        ...data,
        ...data?.localized_data,
        country_code:data?.details?.country_code,
        image:data?.logo,
        email:data?.details?.email,
        google_cities_stores:data?.location,
        tags:selectedTags,


    }
};
export const structure = (tags) => [
    {
        head: "",
        width: "100%",
        list: [
            {
                name: `image`,
                kind: "upload",
                type: 'image',
                label: "Upload Photo",
                placeholder: 'test',
                text: 'JPG and PNG  file size no more than',
                width: "47%",
            },
            {
                width: "47%",
            },
            {
                name: `en.name`,
                kind: "input",
                type: "text",
                label: "Vendor Name(EN)",
                width: "47%",
                placeholder: "Vendor Name (EN)"
            },
            {
                name: `ar.name`,
                kind: "input",
                type: "text",
                label: "Vendor Name(AR)",
                width: "47%",
                placeholder: "Vendor Name(AR)"
            },
            {
                name: `email`,
                kind: "input",
                type: "text",
                label: "Email",
                width: "47%",
                placeholder: "Write Email"
            },


            {
                name: `phone_number`,
                kind: "phone",
                type: "text",
                width: "47%",
                label: 'Phone Number',
                placeholder: "Write phone number"
            },
            {
                name: `password`,
                kind: "input",
                type: "password",
                width: "47%",
                label: 'Password',
                placeholder: "********"
            },
            {
                name: `confirm_password`,
                kind: "input",
                type: "password",
                width: "47%",
                label: 'Confirm Password',
                placeholder: "********"
            },
            {
                name: `tags`,
                kind: "multiSelect",
                isMulti:true,
                type: "text",
                label: "Tag",
                width: "47%",
                placeholder: "Choose tag",
                options:tags,
                tag:true,
            },
        ],

    },

];


export const validationSchema = () =>

    Yup.object({
        en: Yup.object().shape({
            name: Yup.string().required('Required'),
        }),
        ar: Yup.object().shape({
            name: Yup.string().required('Required'),
        }),
    });