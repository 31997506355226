import * as Yup from "yup";

export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_data,
  }
};

export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `image`,
        kind: "upload",
        type: 'image',
        label: "Wrap Photo",
        placeholder: 'test',
        text: 'JPG and PNG  file size no more than',
        width: "47%",
      },
      {
        width: "47%",
      },
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "Wrap Extra Name(EN)",
        width: "47%",
        placeholder: "Write Wrap Extra Name(EN)"
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "Wrap Extra Name(AR)",
        width: "47%",
        placeholder: "Write Wrap Extra Name(AR)"
      },
      {
        name: `price`,
        kind: "input",
        type: "number",
        label: "Price",
        width: "47%",
        placeholder: "Write price",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >SAR</p>
        )
      },
      {
        name: `price_after_discount`,
        kind: "input",
        type: "number",
        label: "Price after discount",
        width: "47%",
        placeholder: "Write price",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >SAR</p>
        )
      },
      {
        name: `count_on_hand`,
        kind: "input",
        type: "number",
        label: "Quantity",
        width: "47%",
        placeholder: "Write Quantity"
      },
      {
        name: `width`,
        kind: "input",
        type: "number",
        label: "Width",
        width: "47%",
        placeholder: "Write Width",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >gm</p>
        )
      },
      {
        name: `height`,
        kind: "input",
        type: "number",
        label: "Height",
        width: "47%",
        placeholder: "Write Weight",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >cm</p>
        ),
      },
      {
        name: `length`,
        kind: "input",
        type: "number",
        label: "Length",
        width: "47%",
        placeholder: "Write Length",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >cm</p>
        )
      },

      {
        name: `weight`,
        kind: "input",
        type: "number",
        label: "Weight",
        width: "47%",
        placeholder: "Write Weight",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >gm</p>
        )
      },
    ],
  },
];


export const validationSchema = (wrapId) =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
    ar: Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
    price: Yup.number().required("required"),
    price_after_discount: Yup.number().required("required"),
    count_on_hand: Yup.number().required("required"),
    image: Yup.string().required('Required'),
    height: Yup.number().required("required"),
    length: Yup.number().required("required"),
    weight: Yup.number().required("required"),
    width: Yup.number().required("required"),
  },
  );